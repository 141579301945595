import { FC, useState } from 'react';
import { Box } from '@mui/system';
import { DefaultModalVariation } from '../types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {
  BackgroundColor,
  BlockPadding,
  ItemPosition,
  TextColor,
} from '@lib/slice-machine/types';
import { SxStyles } from '@lib/theme/sxTheme';
import CloseIcon from '@mui/icons-material/Close';
import { hasRichTextContent } from '@lib/slice-machine/richText';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { mapModalWidthToBreakpoint } from '@lib/slice-machine/blockWidth';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';
import { mapBlockPaddingValue } from '@lib/slice-machine/blockPadding';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';

interface Props {
  slice: DefaultModalVariation;
}

export const DefaultModal: FC<Props> = ({ slice }) => {
  const {
    title,
    subtitle,
    ctaText,
    ctaBackgroundColor,
    ctaTextColor,
    buttonVariant,
    modalTitle,
    modalContent,
    modalWidth,
    paddingTop,
    paddingBottom,
    backgroundColor,
    width,
    panelBorderColor,
    panelBackgroundColor,
    panelPaddingTop,
    panelPaddingBottom,
    panelAlignment,
  } = slice.primary;
  const dynamicStyles = styles({
    ctaTextColor,
    panelAlignment,
    panelBorderColor,
    panelBackgroundColor,
    panelPaddingTop,
    panelPaddingBottom,
  });
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      <Box sx={dynamicStyles.getValue('content')}>
        {hasRichTextContent(title) && (
          <CustomRichText
            render={title}
            containerSx={dynamicStyles.getValue('title')}
          />
        )}
        {hasRichTextContent(subtitle) && (
          <CustomRichText
            render={subtitle}
            containerSx={dynamicStyles.getValue('subtitle')}
          />
        )}
        {ctaText && (
          <Button
            variant={buttonVariant ?? 'contained'}
            color={ctaBackgroundColor ?? undefined}
            sx={dynamicStyles.merge(
              'button',
              buttonVariant === 'outlined' && 'outlinedButton',
            )}
            onClick={handleOpen}
          >
            {ctaText}
          </Button>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={mapModalWidthToBreakpoint(modalWidth)}
      >
        <DialogTitle sx={dynamicStyles.getValue('dialogTitle')}>
          {modalTitle}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={dynamicStyles.getValue('dialogClose')}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={dynamicStyles.getValue('dialogContent')}>
          {hasRichTextContent(modalContent) && (
            <CustomRichText render={modalContent} />
          )}
        </DialogContent>
      </Dialog>
    </SliceLayout>
  );
};

interface StyleProps {
  ctaTextColor: TextColor | null;
  panelAlignment: ItemPosition | null;
  panelBackgroundColor: BackgroundColor | null;
  panelPaddingTop: BlockPadding | null;
  panelPaddingBottom: BlockPadding | null;
  panelBorderColor: TextColor | null;
}

const styles = ({
  panelAlignment,
  panelBackgroundColor,
  panelPaddingTop,
  panelPaddingBottom,
  panelBorderColor,
  ctaTextColor,
}: StyleProps) =>
  new SxStyles({
    content: {
      backgroundColor: panelBackgroundColor,
      textAlign: panelAlignment ?? 'center',
      px: 3,
      pt: mapBlockPaddingValue(
        panelPaddingTop,
        DEFAULT_SPACING_BETWEEN_ELEMENTS,
      ),
      pb: mapBlockPaddingValue(
        panelPaddingBottom,
        DEFAULT_SPACING_BETWEEN_ELEMENTS,
      ),

      ...(panelBorderColor && {
        borderWidth: 5,
        borderStyle: 'solid',
        borderColor: panelBorderColor,
        borderRadius: 2,
      }),
    },
    title: {
      mb: DEFAULT_SPACING_BETWEEN_ELEMENTS / 2,
    },
    subtitle: {
      mb: DEFAULT_SPACING_BETWEEN_ELEMENTS,
    },
    button: {
      mb: 0.5,
      fontSize: 18,
      color: ctaTextColor ?? undefined,
      borderRadius: 5,
    },
    dialogClose: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: 'text.primary',
    },
    dialogTitle: {
      pr: 8,
      fontSize: 30,
    },
    dialogContent: {
      p: (theme) => theme.spacing(0, 3),
      m: (theme) => theme.spacing(2, 0, 3),

      '& p': {
        mt: 0,
        mb: 2,
      },
    },
    outlinedButton: {
      bgcolor: 'white',

      '&:hover': {
        bgcolor: 'white',
      },
    },
  });
